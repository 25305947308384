import { Injectable } from '@angular/core';
import { ModulesFacade } from '@library/store/modules/modules.facade';
import { Room } from '@library/store/rooms/rooms.interface';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { Home } from '../homes/homes.interface';
import { Module } from '../modules/modules.interface';
import { LoadHomeMeasures } from './measures.actions';
import { EnumApiScales, EnumMeasureTypes, TimeRange } from './measures.interface';
import { getHomeLoadStatus, getHomeMeasures, getModuleLoadStatus, getModuleTimeRangeMeasuresTotal, getModuleTimeRangeMeasuresTotals, getModuleTotal, getModulesMeasures, getRoomLoadStatus, getRoomsMeasures, getSelectedTimeMeasures, getSelectedTimeMeasuresTotal, getSelectedTimeModuleMeasures, getSelectedTimeModuleMeasuresTotal, getTimeRangeMeasures, getTimeRangeMeasuresTotal, getTimeRangeMeasuresTotals } from './measures.selectors';

@Injectable()
export class MeasuresFacade {

  constructor(
    public store$: Store,
    public actions$: Actions,
    public modulesFacade: ModulesFacade,
  ) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }

  loadHomeMeasures(modules: [Module, EnumMeasureTypes[]][], rooms: [Room, EnumMeasureTypes[]][], scale: EnumApiScales, timeRange: TimeRange) {
    this.dispatch(LoadHomeMeasures({
      home: {
        id: modules[0]?.[0].homeId ?? rooms[0][0].homeId,
        modules: modules.map(([m, measureTypes]) => {
          return {id: m.id, bridge: m.bridge, types: measureTypes};
        }),
        rooms: rooms.map(([m, measureTypes]) => {
          return {id: m.id, bridge: m.therm_relay, types: measureTypes};
        }),
      },
      scale,
      timeRange,
    }));
  }

  getModulesMeasures(modules: Pick<Module, "id">[]) {
    return this.store$.select(getModulesMeasures(modules));
  }

  getRoomsMeasures(rooms: Pick<Room, "id">[]) {
    return this.store$.select(getRoomsMeasures(rooms));
  }

  getHomeMeasures(home: Home) {
    return this.store$.select(getHomeMeasures(home));
  }

  getModuleTotal(id: string, measureType: EnumMeasureTypes, scale: EnumApiScales, timeRange:TimeRange) {
    return this.store$.select(getModuleTotal({id}, measureType, scale, timeRange));
  }

  getTimeRangeMeasures(homeId:string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange:TimeRange) {
    return this.store$.select(getTimeRangeMeasures(homeId, measureTypes, scale, timeRange));
  }

  getTimeRangeMeasuresTotals(homeId:string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange:TimeRange) {
    return this.store$.select(getTimeRangeMeasuresTotals(homeId, measureTypes, scale, timeRange));
  }

  getTimeRangeMeasuresTotal(homeId:string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange:TimeRange) {
    return this.store$.select(getTimeRangeMeasuresTotal(homeId, measureTypes, scale, timeRange));
  }

  getModuleTimeRangeMeasuresTotals(id: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) {
    return this.store$.select(getModuleTimeRangeMeasuresTotals({id}, measureTypes, scale, timeRange));
  }

  getModuleTimeRangeMeasuresTotal(id: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) {
    return this.store$.select(getModuleTimeRangeMeasuresTotal({id}, measureTypes, scale, timeRange));
  }

  getModuleLoadStatus(id: string, scale: EnumApiScales) {
    return this.store$.select(getModuleLoadStatus(id, scale));
  }

  getRoomLoadStatus(id: string, scale: EnumApiScales) {
    return this.store$.select(getRoomLoadStatus(id, scale));
  }

  getHomeLoadStatus(id: string, scale: EnumApiScales) {
    return this.store$.select(getHomeLoadStatus(id, scale));
  }

  getSelectedTimeMeasures(homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) {
    return this.store$.select(getSelectedTimeMeasures(homeId, measureTypes, scale, time));
  }

  getSelectedTimeMeasuresTotal(homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) {
    return this.store$.select(getSelectedTimeMeasuresTotal(homeId, measureTypes, scale, time));
  }

  getSelectedTimeModuleMeasures(id: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) {
    return this.store$.select(getSelectedTimeModuleMeasures({id}, measureTypes, scale, time));
  }

  getSelectedTimeModuleMeasuresTotal(id: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) {
    return this.store$.select(getSelectedTimeModuleMeasuresTotal({id}, measureTypes, scale, time));
  }
}
