import { Env } from 'src/typings';

export const environment: Partial<Env> = {
  production: true,
  envDomain: 'integration',
  isProdDomain: false,

  logoutUrl: 'https://auth.inte.netatmo.com/logout',
  appUrl: window.location.origin,

  apiUrl: "https://building-api.inte.netatmo.com/api/",
  authUrl: "https://auth.inte.netatmo.com/",
  cookiePrefix: "intenetatmocom",
  langsUrl: "/langs/",
  COOKIE_LOCALE_DOMAIN: '.inte.netatmo.com',

  myAccountUrl: 'https://myconnectedaccount.inte.netatmo.com',

  webPusherUrl: "wss://app.inte.netatmo.com/ws/",
};
