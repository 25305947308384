import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, retry, take } from 'rxjs/operators';
import { EnumApiScales, RawModuleMeasures } from './measures.interface';

export interface HomeMeasuresPayload {
  home: {
    id: string;
    modules?: RawModuleMeasures[],
    rooms?: RawModuleMeasures[],
  }
}

interface HomeMeasuresParams {
  home: {
    id: string,
    modules: {
      id: string,
      bridge?: string,
      type: string,
    }[],
    rooms: {
      id: string,
      bridge: string,
      type: string,
    }[],
  };
  real_time?: boolean;
  scale: EnumApiScales;
  date_begin: number;
  date_end: number;
}

@Injectable()
export class MeasuresService {
  constructor(public http: NgrxHttpClient) {}

  public homeMeasures({home, real_time, scale, date_begin, date_end}: HomeMeasuresParams): Observable<HomeMeasuresPayload> {

    const all = [
      ...home.modules.map(module => (['module', module])),
      ...home.rooms.map(room => (['room', room])),
    ] as ['module' | 'room', {id: string, bridge?: string, type: string}][];

    const chunks = splitArrayChunks(all, 15);

    return forkJoin(chunks.map((chunk) => {
      return this.http.get<HomeMeasuresPayload>('gethomemeasure', {
        home: JSON.stringify({
          ...home,
          modules: chunk.filter(([type]) => type === 'module').map(([, module]) => module),
          rooms: chunk.filter(([type]) => type === 'room').map(([, room]) => room),
        }),
        real_time,
        scale,
        date_begin,
        date_end,
      }).pipe(
        retry(5),
        take(1),
        catchError(() => of({home: {id: home.id, modules: [], rooms: []}} as HomeMeasuresPayload)),
      );
    })).pipe(
      map((arr) => {
        return {
          home: {
            id: home.id,
            modules: arr.reduce((acc, curr) => ([...acc, ...curr.home.modules]), []),
            rooms: arr.reduce((acc, curr) => ([...acc, ...curr.home.rooms]), []),
          }
        }
      })
    );
  }

  public getMeasures(params: {homeId: string, date_begin: number, date_end: number, scale: string, real_time: true, modules: {id: string, bridge?: string, type: string}[]}) {
    return forkJoin(params.modules.map(module => {
      return this.http.post('getmeasure', {
        device_id: module.bridge ?? module.id,
        module_id: module.id,
        date_begin: params.date_begin.toString(),
        date_end: params.date_end.toString(),
        scale: params.scale,
        type: module.type,
      }).pipe(
        catchError((err) => {
          if (err.status === 404) {
            return of({
              body: [],
            });
          }
          else {
            throw err;
          }
        }),
        retry(5),
        map(m => m.body),
        take(1),
        catchError(() => of([])),
      );
    }));
  }

}

function splitArrayChunks<T>(array: T[], chunkSize: number) {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}