import { createSelector } from '@ngrx/store';
import { Home } from '../homes/homes.interface';
import { Module } from '../modules/modules.interface';
import { EnumApiScales, EnumMeasureTypes, HomeMeasuresSets, Measure, MeasuresState, ModuleMeasuresSets, RoomMeasuresSets, TimeRange } from './measures.interface';
import { Room } from '@library/store/rooms/rooms.interface';

export const getModulesMeasures = (modules: Pick<Module, "id">[]) => createSelector(
  (state: {measures: MeasuresState}) => state.measures,
  (measures: MeasuresState) => {
    return modules.map(module => {
      return measures.modules.find(moduleMeasures => moduleMeasures.module.id === module.id) ?? ({} as ModuleMeasuresSets);
    })
  },
);

export const getRoomsMeasures = (rooms: Pick<Room, "id">[]) => createSelector(
  (state: {measures: MeasuresState}) => state.measures,
  (measures: MeasuresState) => {
    return rooms.map(room => {
      return measures.rooms.find(roomMeasures => roomMeasures.room.id === room.id) ?? ({} as RoomMeasuresSets);
    })
  },
);

export const getHomeMeasures = (home: Home) => createSelector(
  (state: {measures: MeasuresState}) => state.measures,
  (measures: MeasuresState) => {
    return measures.homes[home.id] ?? ({} as HomeMeasuresSets);
  },
);

export const getModuleTotal = (module: Pick<Module, "id">, measureType: EnumMeasureTypes, scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  getModulesMeasures([module]),
  (measures: ModuleMeasuresSets[]) => {
    const dateBegin = timeRange.dateBegin.valueOf();
    const dateEnd = timeRange.dateEnd.valueOf();
    if (measures?.[0][measureType]?.[scale]) {
      return measures[0][measureType][scale]
        .reduce((acc, curr) => {
          const isDateInTimeRange = curr.x >= dateBegin && curr.x <= dateEnd;
          if (isDateInTimeRange) {
            return curr.y + acc
          }
          return acc
        }, 0)
    }
  }
);

export const getTimeRangeMeasures = (homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => {
    if (measures?.homes?.[homeId]) {
      const calculatedHome = measures.homes[homeId]
      const timeRangeMeasures = measureTypes
        .map(mType => {
          if (calculatedHome?.[mType]?.[scale]) {
            return calculatedHome[mType][scale]

          }
        })
      return timeRangeMeasures
    }
  }
);

function calculateTimeRangeMeasuresTotals(measures: HomeMeasuresSets | ModuleMeasuresSets, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) {
  const dateBegin = timeRange.dateBegin.valueOf();
  const dateEnd = timeRange.dateEnd.valueOf();
  return measureTypes
    .map(mType => {
      if (measures?.[mType]?.[scale]) {
        return measures[mType][scale]
          .reduce((acc, curr) => {
            const isDateInTimeRange = curr.x >= dateBegin && curr.x <= dateEnd;
            if (isDateInTimeRange) {
              return curr.y + acc
            }
            return acc
          }, 0)
      }
    });
}

export const getTimeRangeMeasuresTotals = (homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => calculateTimeRangeMeasuresTotals(measures?.homes?.[homeId], measureTypes, scale, timeRange),
);

export const getTimeRangeMeasuresTotal = (homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  getTimeRangeMeasuresTotals(homeId, measureTypes, scale, timeRange),
  (totals: number[]) => {
    return totals?.reduce((acc, curr) => curr + acc, 0);
  }
);

export const getModuleTimeRangeMeasuresTotals = (module: Pick<Module, "id">, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  getModulesMeasures([module]),
  ([measures]: ModuleMeasuresSets[]) => calculateTimeRangeMeasuresTotals(measures, measureTypes, scale, timeRange),
);

export const getModuleTimeRangeMeasuresTotal = (module: Pick<Module, "id">, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, timeRange: TimeRange) => createSelector(
  getModuleTimeRangeMeasuresTotals(module, measureTypes, scale, timeRange),
  (totals: number[]) => {
    return totals?.reduce((acc, curr) => curr + acc, 0);
  }
);

export const getModuleLoadStatus = (id: string, scale: EnumApiScales) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => {
    return measures.loadStatus.modules.find(m => m.id === id && m.scale === scale) ?? null;
  }
);

export const getRoomLoadStatus = (id: string, scale: EnumApiScales) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => {
    return measures.loadStatus.rooms.find(r => r.id === id && r.scale === scale) ?? null;
  }
);

export const getHomeLoadStatus = (id: string, scale: EnumApiScales) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => {
    return measures.loadStatus.homes.find(h => h.id === id && h.scale === scale) ?? null;
  }
);

export const getSelectedTimeMeasures = (homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) => createSelector(
  (state: { measures: MeasuresState }) => state.measures,
  (measures: MeasuresState) => {
    const timeMiliseconds = time.valueOf();
    return measureTypes.map(measureType => measures.homes[homeId][measureType][scale].find(m => m.x === timeMiliseconds)?.y ?? 0);
  }
);

export const getSelectedTimeMeasuresTotal = (homeId: string, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) => createSelector(
  getSelectedTimeMeasures(homeId, measureTypes, scale, time),
  (measures: number[]) => {
    return measures.reduce((acc, curr) => acc + curr, 0);
  }
);

export const getSelectedTimeModuleMeasures = (module: Pick<Module, "id">, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) => createSelector(
  getModulesMeasures([module]),
  ([measures]: [ModuleMeasuresSets]) => {
    const timeMiliseconds = time.valueOf();
    return measureTypes.map(measureType => measures[measureType][scale].find(m => m.x === timeMiliseconds)?.y ?? 0);
  }
);

export const getSelectedTimeModuleMeasuresTotal = (module: Pick<Module, "id">, measureTypes: EnumMeasureTypes[], scale: EnumApiScales, time: moment.Moment) => createSelector(
  getSelectedTimeModuleMeasures(module, measureTypes, scale, time),
  (measures: number[]) => {
    return measures.reduce((acc, curr) => acc + curr, 0);
  }
);

